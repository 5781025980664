<template>
    <v-footer dark app inset>
      <v-col class="text-center white--text py-3">
        © 2025 Szafruga Software Inc. All rights reserved.
      </v-col>
    </v-footer>
  </template>
  
  <script>
  export default {
    name: 'Foo-ter',
  };
  </script>
  
  <style scoped>
  .v-footer {
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.2);
    background: none;
    color: white;
  }
  
  .text-center {
    font-size: 0.9rem; 
  }
  </style>
  