<template>
  <v-app>
    <Navbar />
    <v-main class="home" >
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" md="8" lg="6" class="text-center">
            <h1 class="display-1">Mineapolis</h1>
            <p class="subheading">Work in progress.</p>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import Navbar from './components/Navbar.vue';
import Footer from './components/Footer.vue';

export default {
  name: 'Minea-polis',
  components: {
    Navbar,
    Footer,
  },
};
</script>

<style scoped>

.home{
  font-size: 2.5rem;
  background: rgb(0,18,36);
  background: radial-gradient(circle, rgba(0,18,36,1) 0%, rgba(65,9,121,1) 32%, rgba(71,7,148,1) 64%, rgba(115,0,255,1) 100%);
  color: white;
}

.fade-in {
  opacity: 0;
  animation: fadeIn 1s ease-out forwards;
}

.fade-in-delayed {
  opacity: 0;
  animation: fadeIn 1s ease-out forwards;
  animation-delay: 0.5s;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}
</style>

